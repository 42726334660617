@import "./variable.scss";
@import "./media.scss";
.team {
  padding: 30px 0 30px;
  position: relative;
  // background: #191919;
  overflow: hidden;
&:before{
  content: '';
  // background: url(../../public/images/2.jpg) no-repeat center center;

  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
&::after{
  // content: "";
  --tw-gradient-from: #000;
  --tw-gradient-stops: var(--tw-gradient-from),    var(--tw-gradient-to, transparent);
  background-image: linear-gradient(180deg, var(--tw-gradient-stops));
  height: 51px;
  // content: "";
  left: 0;
  position: absolute;
  width: 100%;

  top:0 ;
  
}
  @include md {
    padding: 15px 0;
  }
  .container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 0 10px;

    .heading {
      overflow: hidden;
      padding: 20px 0;
      margin-bottom: 30px;
      > h1 {
        font-size: clamp(2rem, 6vw, 3rem);
        padding-bottom: 20px;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        filter: drop-shadow(2px 6px 4px #00000087);
        line-height: 105%;
        -webkit-filter: drop-shadow(2px 6px 4px #00000087);
        margin-bottom: 0;
        margin-top: 0;
        position: relative;
        width: max-content;
        margin: 0 auto 20px;
        font-weight: 700;
        background-image: -webkit-linear-gradient(top, #efd100, #e2a233 19%, #f0c328 30%, #fff1a3 43%, #ffe13e 50%, #fff 51%, #fff);
        background-size: 1em 2.5em;
        color: #f8b700;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        &::before {
          content: "";
          background: url(../../public/images/after.png);
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          background-repeat: no-repeat;
          background-position: center center;
          bottom: -30px;
        }
      }
    }
    .team__grid {
      padding: 10px 30px;
      display: grid;
    grid-template-columns: 1fr;
      grid-row-gap: 4rem;
      place-items: center
      ;
      grid-column-gap: 1rem;
      @include lg {
        grid-template-columns: 1fr ;
      }
      @include md {
        grid-template-columns: 1fr;
      }
      .item {
      display: grid;
      max-width: 800px;
      grid-template-columns: 1fr 1.5fr;
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;
      @include md{
        grid-template-columns: 1fr  !important;
      }
      .img{
        img{
          width: 100%;
          /* max-width: 280px; */
          border-radius: 14px;
          box-shadow: 0 0 10px #0000004d;
          height: 100%;
          @include md{
          max-width: 300px;
          }
        }
      }
      .detail {
        h2{
          font-size: 1.5rem;
          line-height: 2.25rem;
        }
        h4{
          text-align: left;
          margin-bottom: 1.25rem;
          font-size: .9rem;
          line-height: 1.5rem;
          width: 67%;
          color: darkgray;
        }
        p{
          font-family: 'Montserrat';
          font-weight: 100;
          text-transform: uppercase;
          font-size: 14px;
          word-spacing: 1px;
          position: relative;
          line-height: 132%;
          padding-left: 22px;
          &::before{
            width: 10px;
            margin: var(--bullet-border);
            background: linear-gradient(0deg, #7a1515 1.69%, #b82f2f 48.7%, #781414 98.73%);
            content: "";
            position: absolute;
            height: 100%;
            top: 0;
            left: 2px;
            border-radius: 2px;
          }
        }
      }
      &:nth-child(even){
        grid-template-columns: 1.5fr 1fr;
        .img{
          order: 2;
          @include md{
            order: unset !important;
          }

        }
      }
      }
    }
  }
}

@media (min-width: 0) and (max-width: 768px) {
  .team {
    .container {
      .heading {
        > h1 {
          font-size: clamp(2.5rem, 7vw, 3rem);
          > span {
          }
        }
      }
      .team__grid {
        padding: 10px 10px;
        .item {
          .img {
            > img {
            }
          }
          .detail {
            > h2 {
            }
            > h4 {
            }
            > div {
              > i {
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 0) and (max-width: 484px) {
  .team {
    .container {
      .heading {
        > h1 {
          > span {
          }
        }
      }
      .team__grid {
        .item {
          max-width: 300px;
          .img {
            > img {
            }
          }
          .detail {
            > h2 {
            }
            > h4 {
            }
            > div {
              > i {
              }
            }
          }
        }
      }
    }
  }
}
.owl-carousel .owl-stage-outer {
  position: relative;
  padding: 34px 0 !important;
  overflow: hidden;
  -webkit-transform: translate3d(0px, 0px, 0px);
}
