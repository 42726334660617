@import "./variable.scss";
@import "./media.scss";

.roadmap {
  position: relative;
  // background: linear-gradient(137deg, #191919 90%, #343333);
  padding: 80px 0 80px;
  position: relative;
  overflow: hidden;
  z-index: 1;

  @include md {
    padding: 30px 0;
  }

  &::before {
  }

  &:after {
    // content: "";
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, transparent);
    background-image: linear-gradient(180deg, var(--tw-gradient-stops));
    height: 51px;
    // content: "";
    left: 0;
    position: absolute;
    width: 100%;
    transform: rotateX(180deg);
    bottom: 0%;
  }

  .heading {
    overflow: hidden;
    padding: 20px 0;

    > h2 {
      font-size: clamp(2rem, 6vw, 3rem);
      padding-bottom: 40px;
      text-align: center;
      color: #fff;
      text-transform: uppercase;
      filter: drop-shadow(2px 6px 4px #00000087);
      line-height: 105%;
      -webkit-filter: drop-shadow(2px 6px 4px #00000087);
      margin-bottom: 0;
      margin-top: 0;
      position: relative;
      width: max-content;
      margin: 0 auto 40px;
      font-weight: 700;
      background-image: -webkit-linear-gradient(
        top,
        #efd100,
        #e2a233 19%,
        #f0c328 30%,
        #fff1a3 43%,
        #ffe13e 50%,
        #fff 51%,
        #fff
      );
      background-size: 1em 2.5em;
      color: #f8b700;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      &::before {
        content: "";
        background: url(../../public/images/after.png);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        background-repeat: no-repeat;
        background-position: center center;
        bottom: -20px;
      }
    }
  }

  // .grid{
  //   .listing{
  //     display: grid;
  //     place-items: center;
  //     grid-row-gap: 6rem;
  //     .start {
  //       max-width: 500px;
  //     background: #1b1d1e;
  //     padding: 40px 20px 20px;
  //     border-radius: 10px;
  //     -webkit-border-radius: 25px;
  //     -moz-border-radius: 10px;
  //     -ms-border-radius: 10px;
  //     -o-border-radius: 10px;
  //     position: relative;
  //     border: 1px solid #c77e19;
  //     box-shadow: 0 0 8px #00000033;
  //     background-color: rgba(48,48,48,.5);
  //     border-color: #dcdad859 #584230 #584230 #dcdad84d;
  //     border-style: solid;
  //     border-width: 1px;
  //       ul{
  //         margin-left: 30px;
  //       }
  //       h2{
  //         position: absolute;
  //         top: -30px;
  //         left: 50%;
  //         transform: translateX(-50%);
  //         padding: 12px 26px 10px;
  //         background: #282d34;
  //         border-radius: 7px;
  //         border: 1px solid #c77e19;
  //       }
  // }
  //   }
  // }
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0px 30px;
    max-width: 1100px;
    margin: 0 auto;
    position: relative;
    z-index: 0;

    &:before {
      content: "";
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%) rotate(45deg);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      width: 33px;
      height: 33px;
      background: #120600;
      box-shadow: none;
      border: 4px double #d2ba71;

      @include md {
        content: unset;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      width: 2px;
      height: 100%;
      /* background: rgb(210 186 113 / 32%); */
      box-shadow: inset 0 0 1px #191919;
      z-index: -1;
      border-right: 2px dashed #7d6312;
@include md{
  content: unset;
}
      @media (max-width: 1300px) {
        width: 1px;
      }
    }

    > .listing {
      padding: 20px 20px;
      height: max-content;
      display: grid;
      grid-row-gap: 2rem;

      > .item:nth-child(even) {
        .start {
          order: 2;
          position: relative;
          justify-self: start !important;
          transform: rotate(2deg);
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(2deg);
          -ms-transform: rotate(2deg);
          -o-transform: rotate(2deg);

          &::before {
            // content: "";

            right: calc(100% + 49px) !important;
            left: unset !important;
            top: 50%;
            @include md {
              right: calc(100% + -12px) !important;
              transform: translateY(-50%) rotate(0deg);
              -webkit-transform: translateY(-50%) rotate(0deg);
              -moz-transform: translateY(-50%) rotate(0deg);
              -ms-transform: translateY(-50%) rotate(0deg);
              -o-transform: translateY(-50%) rotate(0deg);
            }
          }

          &:after {
            content: "";
            left: unset !important;
            right: 100%;

            @include md {
              content: unset;
            }
          }
        }

        .end {
          order: 1;
          position: relative;

          &:after {
            // content: "";
            // background: url(http://localhost:3000/static/media/walpapper_red.0fc27236bf5ba1754078.png);
            position: absolute;
            top: 50%;
            left: calc(100% + 15px);
            width: 53px;
            right: unset !important;
            height: 4px;
            background: #ae1144 !important;
            transform: translateY(-232%);
          }

          > .circle {
            cursor: pointer;
            left: 125%;

            @include lg {
              left: 115%;
            }
          }
        }
      }

      > .item {
        display: grid;
        grid-template-columns: 1fr 1fr;
        position: relative;
        margin-bottom: 20px;
        grid-gap: 1rem 120px;
        margin-top: -70px;
        &:nth-child(1) {
          margin-top: 0;
        }
        @include md {
          grid-template-columns: 1fr;
          grid-gap: 3rem 120px;
        }

        > .start {
          width: 100%;
          background: linear-gradient(
            262.31deg,
            rgba(255, 255, 255, 0.1) 0.27%,
            rgba(255, 255, 255, 0) 99.02%
          );
          padding: 10px;
          -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
          border-radius: 0px;
          padding: 28px 20px 28px 36px;
          border: 1px dashed #7d6312;
          cursor: pointer;
          /* margin: auto; */
          box-shadow: 0 0 10px #0000004d;
          position: relative;
          justify-self: end;
          align-self: center;
          height: max-content;
          transform: rotate(360deg);
          box-shadow: inset 0 0 26px #00000052, 0 0 20px #00000080;

          @include md {
            order: 2;
          }

          &::before {
            content: "";
            position: absolute;
            background: #120600;
            width: 25px;
            height: 25px;
            top: 50%;
            left: 50%;
            border-radius: 100px;
            transform: translateY(-50%) rotate(2deg);
            left: calc(100% + 49px);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%) rotate(2deg);
            -ms-transform: translateY(-50%) rotate(2deg);
            -o-transform: translateY(-50%) rotate(2deg);
            z-index: 11;
            box-shadow: none;
            border: 4px double #d2ba71;

            @include md {
              left: calc(100% + -12px) !important;
              transform: translateY(-50%) rotate(0deg);
              -webkit-transform: translateY(-50%) rotate(0deg);
              -moz-transform: translateY(-50%) rotate(0deg);
              -ms-transform: translateY(-50%) rotate(0deg);
              -o-transform: translateY(-50%) rotate(0deg);
            }
          }

          &::after {
            content: "";
            position: absolute;
            width: 61px;
            left: 100%;

            height: 1px;
            background: none;
            top: 50%;
            border-top: 2px dashed #7d6312;

            @include md {
              content: unset;
            }
          }

          > h2 {
            background-image: -webkit-linear-gradient(
              top,
              #efd100,
              #e2a233 19%,
              #f0c328 30%,
              #fff1a3 43%,
              #ffe13e 50%,
              #fff 51%,
              #fff
            );
            background-size: 1em 2.5em;
            color: #f8b700;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          ul {
            padding-left: 12px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            letter-spacing: 2px;
            font-size: 18px;
            margin-top: 10px;
            line-height: 160%;

            > li {
              font-family: 'Poppins';
              font-size: 15px;
              text-transform: uppercase;
              margin-left: -10px;
              letter-spacing: 0px;
              font-weight: 100;
            }
          }
        }

        > .end {
          margin: 0 auto;
          width: max-content;
          position: relative;
          text-align: center;
          width: 200px;
          border-radius: 100px;
          display: grid;
          place-content: center;
          z-index: 1;

          @include md {
            width: 150px;
          }

          &::after {
            // content: "";
            // background: url(../../public/images/Background_simple/walpapper_red.jpg);
            position: absolute;
            top: 50%;
            right: calc(100% + 15px);
            width: 53px;
            height: 4px;
            background: #ce1855 !important;
            transform: translateY(-232%);
          }

          > img {
            width: 100%;
            border-radius: 6px;
            box-shadow: 05px 15px 8px #0000004d;
            border: 2px solid #ffd7001f;
          }
        }
      }
    }
  }
}
