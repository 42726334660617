@import "./variable.scss";
@import "./media.scss";
// #a07942
.about {
  padding: 40px 0 40px;
  position: relative;
  background: linear-gradient(45deg, #030202f2, transparent);
  height: 70vh;
  display: grid;
  align-items: center;
>span{
  content: "";
  --tw-gradient-from: #000;
  --tw-gradient-stops: #000,
  var(--tw-gradient-to, transparent);
  background-image: linear-gradient(180deg, var(--tw-gradient-stops));
  height: 70px;
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  transform: rotateX(360deg);
  top: 0;
}
>p{
  content: "";
  --tw-gradient-from: #000;
  --tw-gradient-stops: #000,
  var(--tw-gradient-to, transparent);
  background-image: linear-gradient(180deg, var(--tw-gradient-stops));
  height: 70px;
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  transform: rotateX(180deg);
  bottom: 0;
}
  &::before {
    content: "";
    background: url(../../public/images/features.fd80684.webp) no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: 0;
    background-size: cover;
  }
  &:after {
    content: "";
    --tw-gradient-from: #000;
    --tw-gradient-stops: #000, var(--tw-gradient-to, transparent);
    background-image: linear-gradient(180deg, var(--tw-gradient-stops));
    height: 70px;
    content: "";
    left: 0;
    position: absolute;
    width: 100%;
    transform: rotateX(180deg);
    bottom: 100%;
  }
  @include md {
    padding: 20px 0;
  }
  .container {
    margin: 40px auto;
    max-width: 1200px;
    .about__grid {
      display: grid;
      grid-template-columns: 1fr;
      place-items: center;
      .email__outer {
        padding: 70px 100px;
        text-align: center;
        position: relative;
        background: rgba(0, 0, 0, 0.75);
        @include md{
          padding: 70px 20px;
          width: 92%;
        }
        @include sm6{
          padding: 70px 10px;
        }
        >div{
          >div{
            >div{
              background-image: -webkit-linear-gradient(top, #efd100, #e2a233 19%, #f0c328 30%, #fff1a3 43%, #ffe13e 50%, #fff 51%, #fff);
    background-size: 1em 2.5em;
    color: #f8b700;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    bottom: 16px;
            }
            display: flex;
            align-items: center;
            justify-content: center;
            button {
              text-align: center;
              position: relative;
              cursor: pointer;
              z-index: 1;
              padding: 16px 30px;
              font-size: 18px;
              border-radius: 8px;
              margin-left: 5px;
              display: block;
              font-weight: bold;
              font-size: 18px;
              border-radius: 6px;
              background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
              --tw-gradient-from: #ef5d5d;
              --tw-gradient-stops: var(--tw-gradient-from),
                          var(--tw-gradient-to, rgba(239, 93, 93, 0));
              --tw-gradient-to: #932828;
              --tw-text-opacity: 1;
              color: rgba(255, 255, 255, var(--tw-text-opacity));
              &::before {
                background-image: linear-gradient(
                  to right,
                  var(--tw-gradient-stops)
                );
                --tw-gradient-from: #781414;
                --tw-gradient-stops: var(--tw-gradient-from),
                  var(--tw-gradient-to, rgba(120, 20, 20, 0));
                --tw-gradient-stops: var(--tw-gradient-from), #b82f2f,
                  var(--tw-gradient-to, rgba(184, 47, 47, 0));
                --tw-gradient-to: #781414;
                z-index: -1;
                content: "";
                position: absolute;
                left: 1px;
                top: 1px;
                width: calc(100% - 2px);
                height: calc(100% - 2px);
                border-radius: 10px;
              }
            }
          
          }
        }
      
        input {
          width: 100%;
          padding: 17px 29px;
          border-radius: 0px;
          border: 0;
          outline: gold;
          color: black;
          text-transform: unset;
    font-family: 'Poppins';
          transform: translateY(-1px);
          margin-right: 0px;
          background: linear-gradient(0deg, #ceb15d, #f2da94);
          border-radius: 0.5rem;
          display: block;
          --tw-gradient-from: #ef5d5d;
          --tw-gradient-stops: var(--tw-gradient-from),
            var(--tw-gradient-to, rgba(239, 93, 93, 0));
          --tw-gradient-to: #932828;
          --tw-text-opacity: 1;
          color: rgba(0, 0, 0, var(--tw-text-opacity));
          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: black;
            opacity: 1; /* Firefox */
            font-family: "Trajan Pro Regular";
          }
          
          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: "Trajan Pro Regular";
            color: black;
          }
          
          &::-ms-input-placeholder { /* Microsoft Edge */
            color: black;
            font-family: "Trajan Pro Regular";
          }
          &::before {
            background-image: linear-gradient(
              to right,
              var(--tw-gradient-stops)
            );
            --tw-gradient-from: #ceb15d;
            --tw-gradient-stops: var(--tw-gradient-from),
              var(--tw-gradient-to, rgba(206, 177, 93, 0));
            --tw-gradient-stops: var(--tw-gradient-from), #f2da94,
              var(--tw-gradient-to, rgba(242, 218, 148, 0));
            --tw-gradient-to: #ceb15d;
            z-index: -1;
            content: "";
            position: absolute;
            left: 1px;
            top: 1px;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            border-radius: 10px;
          }
        }
        button {
          
        }
        > span {
          background: url(../../public/images/bbox.png) 50% / contain no-repeat;
          display: block;
          height: 20px;
          position: absolute;
          width: 20px;
        }
        > span:nth-child(1) {
          top: -10px;
          left: -10px;
        }
        > span:nth-child(2) {
          top: -10px;
          right: -10px;
        }
        > span:nth-child(3) {
          bottom: -10px;
          left: -10px;
        }
        > span:nth-child(4) {
          bottom: -10px;
          right: -10px;
        }
      }
    }
  }
}

@media (min-width: 0) and (max-width: 768px) {
  .about {
    .container {
      .about__grid {
        grid-template-columns: 1fr;
        grid-row-gap: 3rem;
        > .img {
          order: 2;

          > img {
          }
        }
      }
      .details {
        order: 1;

        > div {
          > h2 {
            > span {
            }
          }
          > p {
          }
        }
      }
    }
  }
}
@keyframes Utility_cardL__gYhso {
  0%,
  100% {
    transform: translateY(0) translateX(-33%);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateY(-8px) translateX(-33%);
  }
  20%,
  40%,
  60% {
    transform: translateY(8px) translateX(-33%);
  }
  80% {
    transform: translateY(6.4px) translateX(-33%);
  }
  90% {
    transform: translateY(-6.4px) translateX(-33%);
  }
}
