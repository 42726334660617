@import "./variable.scss";
@import "./media.scss";

header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
  transition: 0.3s all;
  position: fixed;
  // background: #252b2d;

  .outer {
    .container {
      max-width: 1300px;
      margin: 0 auto;

      .nav__grid {
        padding: 20px 5px;
        align-items: center;
        display: grid;
        grid-template-columns: 190px 3fr 1fr;
        .logo {
          justify-self: start;
          max-width: 100px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        nav {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0;
          @include lg {
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 100;
            height: 100vh;
            right: -100%;
            overflow: hidden;
            justify-content: center !important;
            background: #000000;
            transition: all 400ms ease 0s;
          }
          ul {
            display: flex;
            justify-content: center;
            list-style: none;
            gap: 1rem;
            @include lg {
              align-items: center;
              flex-direction: column;
            }
            li.nav__links {
              @include -lg {
                display: none;
              }
            }

            > li.links {
              display: flex;
              gap: 15px;
              @include -lg {
                display: none;
              }
            }

            > li {
              padding: 0 5px;
              margin-right: 5px;
              position: relative;
              transition: 0.3s all;
              font-size: 18px;
              transition: 0.1s all;
              position: relative;

              > a {
                transition: 0.3s all;
                -webkit-transition: 0.3s all;
                -moz-transition: 0.3s all;
                -ms-transition: 0.3s all;
                -o-transition: 0.3s all;
                font-weight: bold;
                text-shadow: 10px 4px 27px black;
                transition: all 0.25s cubic-bezier(0.23, 1, 0.32, 1) 0s;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-image: -webkit-linear-gradient(
                  top,
                  #efd100,
                  #e2a233 19%,
                  #f0c328 30%,
                  #fff1a3 43%,
                  #ffe13e 50%,
                  #fff 51%,
                  #fff
                );
                background-size: 1em 2.5em;
                color: #f8b700;
                display: inline-block;
                line-height: 1.5em;
                position: relative;
                z-index: auto;
                background-position: center 0;
                @include lg {
                  font-family: "Button";
                }
                svg {
                  fill: #dfdfdf;
                }

                &:hover {
                  color: #b94841;
                  letter-spacing: 4px;
                }

                @include lg {
                  font-size: clamp(3em, 11vw, 4em);
                  font-weight: 900;
                  line-height: 1;
                }
              }
            }
          }
        }

        > .nav__btns {
          justify-self: end;
          display: flex;
          align-items: center;
          gap: 0px;
          a.wallet {
            position: relative;
            padding: 2px;
            border-radius: 10px;
            background: linear-gradient(90deg, #dcdad8, #584230);
            display: inline-block;
            justify-content: space-between;
            white-space: nowrap;

            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1;
            text-decoration: none;
            button {
              text-align: center;
              position: relative;
              cursor: pointer;
              z-index: 1;
              padding: 14px 28px 10px;
              font-size: 18px;
              border-radius: 8px;
              display: block;
              font-weight: bold;
              font-size: 18px;
              border-radius: 0.5rem;
              background-image: linear-gradient(
                to bottom,
                var(--tw-gradient-stops)
              );
              --tw-gradient-from: #ef5d5d;
              --tw-gradient-stops: var(--tw-gradient-from),
                var(--tw-gradient-to, rgba(239, 93, 93, 0));
              --tw-gradient-to: #932828;
              --tw-text-opacity: 1;
              color: rgba(255, 255, 255, var(--tw-text-opacity));
              &::before {
                background-image: linear-gradient(
                  to right,
                  var(--tw-gradient-stops)
                );
                --tw-gradient-from: #781414;
                --tw-gradient-stops: var(--tw-gradient-from),
                  var(--tw-gradient-to, rgba(120, 20, 20, 0));
                --tw-gradient-stops: var(--tw-gradient-from), #b82f2f,
                  var(--tw-gradient-to, rgba(184, 47, 47, 0));
                --tw-gradient-to: #781414;
                z-index: -1;
                content: "";
                position: absolute;
                left: 1px;
                top: 1px;
                width: calc(100% - 2px);
                height: calc(100% - 2px);
                border-radius: 10px;
              }
            }
            &::before {
              content: "";
              position: absolute;
              left: 1px;
              top: 1px;
              width: calc(100% - 2px);
              height: calc(100% - 2px);
              border-radius: 10px;
              background: #584230;
            }
          }
          a {
            margin-right: 12px;

            svg {
              height: 55px;
              width: 60px;
              border-radius: 6px;
              padding: 5px 0px;
              fill: #fff;

              @include lg {
                display: none;
              }
            }
          }

          a.dis {
            margin-right: 0;

            svg {
              padding: 5px 8px;
            }
          }

          > button:hover {
            background-color: $main;
          }
        }
      }
    }
  }
}
.modal- {
  position: fixed;
  top: 50%;
  left: 50%;
  background: #607d8b;
  padding: 40px 0px 20px 0px;
  border-radius: 0px;
  transition: 0.3s transform, opacity 0.3s;
  transition-timing-function: ease-out;
  z-index: 1111;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0);
  max-width: 350px;
  width: 100%;
  height: 200px;
  display: grid;
  place-content: center;

  box-shadow: 0 0 10px #00000080;
  > svg {
    position: fixed;
    cursor: pointer;
    top: 20px;
    right: 5%;
  }

  p {
    font-size: 25px;
    width: 80%;
    margin: auto;
    text-align: center;
    font-weight: 900;
    margin-bottom: 15px;
    line-height: 1.3;
  }

  .ico {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      transform: scale(3);
      margin-bottom: 30px;
    }
  }

  .warn {
    svg {
      fill: #f1c40f;
    }
  }

  .info {
    svg {
      fill: #3498db;
    }
  }

  .error {
    svg {
      fill: #e74c3c;
    }
  }

  .success {
    svg {
      fill: #07bc0c;
    }
  }
}

.shadow- {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #00000057;
  height: 100%;
  z-index: 1110;
  cursor: pointer;
}

.icons {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000000000000;
  right: 1%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;

  @include lg {
    display: none;
  }

  a {
    img,
    svg {
   
      width: 40px;
      height: auto;
      fill: transparent;

      position: relative;

      filter: brightness(0.85);
    }
  }

  a.opensea {
    img {
      padding: 0;
    }
  }
}

.nav__active {
  // background: #210e37;
  // -webkit-backdrop-filter: blur(1px);
  // backdrop-filter: blur(1px);
}

.auto_scroll {
  position: fixed;
  bottom: 5%;
  right: 5%;
  cursor: pointer;
  transition: 0.3s all;
  z-index: 1001;
  transform: scale(0);

  > i {
    width: 50px;
    background: #4863db;
    height: 50px;
    place-content: center;
    border-radius: 100px;
    pointer-events: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 1px solid #dbd9e01f;
  }
}

@media (min-width: 0) and (max-width: 992px) {
  header {
    .nav__grid {
      grid-template-columns: 1fr 1.5fr !important;

      .logo {
      }

      .nav__btns {
        > div {
          cursor: pointer;
          height: 48px;
          position: absolute;
          transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
          -webkit-user-select: none;
          user-select: none;
          width: 48px;
          outline: none;
          transform: none;
          right: 2%;
          z-index: 1111;
          top: 19%;

          > span {
            &:nth-child(1) {
              background: #fff;
              height: 4px;
              left: 8px;
              position: absolute;
              width: 38px;
              top: 13px;
              transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
              transform: none;
            }

            &:nth-child(2) {
              background: #fff;
              height: 4px;
              left: 8px;
              position: absolute;
              width: 38px;
              top: 23px;
              transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
              opacity: 1;
            }

            &:nth-child(3) {
              background: #fff;
              height: 4px;
              left: 8px;
              position: absolute;
              width: 38px;
              top: 33px;
              transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
              transform: none;
            }
          }
        }
      }
    }
  }
}
