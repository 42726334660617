.loader {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.progress__wrapper {
    width: 260px;
    margin-top: 15px;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*justify-content: center;*/
}

.loader__logo {
    width: 300px;
}

.css-eglki6-MuiLinearProgress-root {
    height: 15px !important;
    background: rgba(196, 196, 196, 0.2) !important;
}

.css-5xe99f-MuiLinearProgress-bar1 {
    background: #f0c328 !important;
}
