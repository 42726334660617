@import "./variable.scss";
@import "./media.scss";
.accordian {
  padding: 30px 0 30px;
  position: relative;
  z-index: 0;
 

&::after{
  content: "";
  --tw-gradient-from: #000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, transparent);
  background-image: linear-gradient(180deg, var(--tw-gradient-stops));
  height: 82px;
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  top: 0;
  
}
.cloud{
  content: "";
  --tw-gradient-from: #000;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, transparent);
  background-image: linear-gradient(180deg, var(--tw-gradient-stops));
  height: 50px;
  content: "";
  left: 0;
  position: absolute;
  bottom: 100%;
  width: 100%;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
  @include md {
    padding: 15px 0;
  }

  .container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 10px;
    .heading {
      overflow: hidden;
      padding: 30px 20px;
      > h1 {
        font-size: clamp(2rem, 6vw, 3rem);
        text-transform: uppercase;
        text-align: center;
        color: #ffffff;
        line-height: 105%;
        filter: drop-shadow(2px 6px 14px #00000087);
        line-height: 105%;
        width: 70%;
        margin: auto;
        font-weight: 700;
        position: relative;
        background-image: -webkit-linear-gradient(top, #efd100, #e2a233 19%, #f0c328 30%, #fff1a3 43%, #ffe13e 50%, #fff 51%, #fff);
        background-size: 1em 2.5em;
        color: #f8b700;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        &::before {
          content: "";
          background: url(../../public/images/after.png);
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          background-repeat: no-repeat;
          background-position: center center;
          bottom: -43px;
        }
      }
    }
    .accordian__grid {
      max-width: 900px;
      margin: 30px auto 0;

      > div {
        > div {
          margin: 10px;
          background: none;
          border-bottom: 1px solid #bca352;
          border-radius: 4px;
          padding: 1px 2px;
          background: none;
          border-radius: 0;
          box-shadow: none !important;
          padding: 10px 0;
          svg {
            fill: currentColor;
            width: 1em;
            height: 1em;
            display: inline-block;
            font-size: 1.5rem;
            transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            flex-shrink: 0;
            -webkit-user-select: none;
            user-select: none;
            background: no-repeat;
            border-radius: 100px;
            -webkit-border-radius: 100px;
            -moz-border-radius: 100px;
            -ms-border-radius: 100px;
            -o-border-radius: 100px;
          }
          > div {
          }
          > div:nth-child(2) {
            // border-top: 1px solid #ffb800b8;
          }
        }
      }
    }
  }
}

.makeStyles-heading-2 {

  font-size: 18px !important;
}
.makeStyles-heading-2 {
  font-size: 0.9375rem;
  font-weight: 400;
  font-size: 20px !important;
  background-image: -webkit-linear-gradient(top, #efd100, #e2a233 19%, #f0c328 30%, #fff1a3 43%, #ffe13e 50%, #fff 51%, #fff);
  background-size: 1em 2.5em;
  color: #f8b700;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
p.MuiTypography-root.MuiTypography-body1 {
 

  font-weight: 400 !important;
  text-transform: uppercase;

  @include lg {
    font-size: 16px !important;
  }
  @include sm {
    font-size: 15px !important;
  }
}

.MuiTypography-body1 {
  font-size: 1rem;
  font-family: 'Trajan Pro Regular' !important;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
// font-size: clamp(3rem, 7vw, 3.5rem);
@media (min-width: 0) and (max-width: 768px) {
  .accordian {
    .container {
      .heading {
        > h1 {
          font-size: clamp(2rem, 7vw, 3rem);

          > span {
          }
        }
      }
      .accordian__grid {
        > div {
          > div {
            svg {
            }
            > div {
            }
            > div:nth-child(2) {
            }
          }
        }
      }
    }
  }
}
.MuiAccordionDetails-root {
  >P{
    font-family: "Poppins" !important;
    font-weight: 100;
  }
}