// $purple--light: #50317c; //White Mode Main Text COLOR
$purple--light: #d44912; //White Mode Main Text COLOR
$black--light: #000000ab; //White Mode Sub Text COLOR
$white--light: white; //White Mode BackGround COLOR
// $white--light: rgb(231, 70, 21); //White Mode BackGround COLOR
$shadow-color--light: rgba(0, 0, 0, 0.5); //White Mode Shadow Color
$family--light: "Poppins", sans-serif;
// $family--light: "gothik", sans-serif;
$family-des--light: "Nunito", sans-serif;

$purple--dark: #956fca; //Black Mode Main Text COLOR
$black--dark: #9b8888ab; //Black Mode Sub Text COLOR
$white--dark: rgb(29, 27, 27); //Black Mode BackGround COLOR
$shadow-color--dark: rgba(85, 83, 83, 0.5);
$family--dark: "Poppins", sans-serif;
$family-des--dark: "Nunito", sans-serif;
$border:1px solid #70696945;
$dark:hwb(330 7% 92%);
$main:#ffb800;
$bgGragient: #e91e63;


