@import "./variable.scss";
@import "./media.scss";

.banner {
  padding-top: 70px;
  position: relative;
  height: 100%;
  background: radial-gradient(#0000007d, transparent);
height: 100vh;
@include sm6{
  height: 70vh;
}
>video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

  .container {
    padding: 60px 10px;
    z-index: 0;
    overflow: hidden;
    padding-top: 0px;
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
    place-content: center;
    max-width: 1200px;
    margin: auto;
    grid-column-gap: 2rem;
    width: 50%;
    text-align: center;

    @include lg {
      width: 60%;
    }

    @include md {
      width: 90%;
    }

 

    .item:nth-child(1) {
      margin: 150px 0;
      h1 {
        font-size: 3.5rem;
        line-height: 100%;
        font-weight: 700;
        line-height: 118%;
        text-transform: uppercase;
        filter: drop-shadow(2px 6px 14px #00000087);
        font-size: clamp(2.5rem, 7vw, 3rem);
        background-image: -webkit-linear-gradient(top, #efd100, #e2a233 19%, #f0c328 30%, #fff1a3 43%, #ffe13e 50%, #fff 51%, #fff);
        background-size: 1em 2.5em;
        color: #f8b700;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transform: translateY(128px);

      
      }

     
    }
  }

  .imges {
    transform: translateY(-90%);
    display: flex;
    justify-content: space-between;
    -webkit-transform: translateY(-90%);
    -moz-transform: translateY(-90%);
    -ms-transform: translateY(-90%);
    -o-transform: translateY(-90%);

    @include md {
      display: none;
    }

    img {
      max-width: 333px;
      width: 100%;

      @include lg {
        max-width: 200px;
      }
    }
  }
}