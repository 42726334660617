@import "./variable.scss";
@import "./media.scss";
.center {
  transform: scale(1.1) !important;
  -webkit-transform: scale(1.1) !important;
  -moz-transform: scale(1.1) !important;
  -ms-transform: scale(1.1) !important;
  -o-transform: scale(1.1) !important;
}
.BannerSlider {
  padding: 80px 0;
  // background: linear-gradient(45deg, #030202f2, transparent);
  position: relative;
  &:before{
    // content: '';
    background: url(http://localhost:3000/static/media/map.636ecab277517691f459.jpg) no-repeat center center;
    width: 100%;
    height: 100%;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: .7;
  }
  .heading {
    overflow: hidden;
    padding: 20px 0;
    > h2 {
      font-size: clamp(2rem, 6vw, 3rem);
      padding-bottom: 30px;
      text-align: center;
      color: #fff;
      text-transform: uppercase;
      filter: drop-shadow(2px 6px 4px #00000087);
      line-height: 105%;
      -webkit-filter: drop-shadow(2px 6px 4px #00000087);
      margin-bottom: 0;
      margin-top: 0;
      position: relative;
      width: max-content;
      margin: 0 auto 30px;
      font-weight: 700;
      background-image: -webkit-linear-gradient(top, #efd100, #e2a233 19%, #f0c328 30%, #fff1a3 43%, #ffe13e 50%, #fff 51%, #fff);
      background-size: 1em 2.5em;
      color: #f8b700;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      &::before {
        content: '';
        background: url(../../public/images/after.png);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        background-repeat: no-repeat;
        background-position: center center;
        bottom: -30px;
      }
    }
  }
  .container {
    max-width: 1200px;
    margin: 30px auto;
    .item{
      border-radius: 25px;
      overflow: hidden;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      background: #9f2a2a1c;
      border: 1px solid #531a1333;
max-width: 380px;
margin: auto;
@include sm{
  max-width: 300px;
}
}
    .nft {
      padding: 18px 10px;
    
      box-shadow: 0 0 10px #0000004d; 
      backdrop-filter: blur(10px);
      @include sm{
        // max-width: 300px;
        // margin: auto;
      }
      &:hover{
        .main{
          video{
            transform: scale(1.2);
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            -ms-transform: scale(1.2);
            -o-transform: scale(1.2);
            -webkit-transform:scale();
}
        }
      }
      .top {
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        img {
          width: 40px;
          border-radius: 100px;
          width: 40px;
          object-fit: cover;
          margin-right: 10px;
        }
        span {
          user-select: auto;
          font-weight: bold;
          font-size: 19px;
          /* -webkit-text-stroke-width: 1px; */
          /* -webkit-text-stroke-color: #1c0436; */
        }
      }
      .main {
        overflow: hidden;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        video
      
{
          border-radius: 13px;
          margin: 3px 1px;
          object-fit: cover;
          background: #020202;
          box-shadow: 0 0 10px #00000033;
          transition:    3s all    ;
          -webkit-transition:    3s all    ;
          -moz-transition:    3s all    ;
          -ms-transition:    3s all    ;
          -o-transition:    3s all    ;
}
      }
      .bottom {
        padding: 10px 10px 0 10px;
        h4 {
          user-select: auto;
          color: #f7f7f7;
          font-size: 19px;
          -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: -webkit-linear-gradient(top, #efd100, #e2a233 19%, #f0c328 30%, #fff1a3 43%, #ffe13e 50%, #fff 51%, #fff);
    background-size: 1em 2.5em;
    color: #f8b700;
        }
        h6 {
          user-select: auto;
          color: darkgray;
          padding: 4px 0;
          -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: -webkit-linear-gradient(top, #efd100, #e2a233 19%, #f0c328 30%, #fff1a3 43%, #ffe13e 50%, #fff 51%, #fff);
    background-size: 1em 2.5em;
    color: #f8b700;
        }
      }
    }
  }
}
