@import "./variable.scss";
@import "./media.scss";

footer {
  // background: #070809ba;
  position: relative;

  > .container {
    max-width: 1200px;
    margin: 0 auto;


    > .grid {
      .logo {
        text-align: center;
        cursor: pointer;

        img {
          max-width: 200px;
        }
      }

      > .icons {
        display: flex;
        justify-content: center;
        align-items: center;

        > div:nth-child(3) {
          margin-right: 0;
        }

        a {
          -webkit-user-select: auto;
          background-image: url(../../public/images/bar.webp);
          height: 50px;
          align-items: center;
          justify-content: center;
          width: 50px;
          border-radius: 100px;
          border: 1px solid #c77e19;
          box-shadow: 0 0 2px #0000004d;
          padding: 10px;
          display: flex;
        }

        .item {
          svg {
         
            transition: 0.3s all;
            -webkit-transition: 0.3s all;
            -moz-transition: 0.3s all;
            -ms-transition: 0.3s all;
            -o-transition: 0.3s all;
            font-weight: bold;
            text-shadow: 10px 4px 27px black;
            transition: all 0.25s cubic-bezier(0.23, 1, 0.32, 1) 0s;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-image: -webkit-linear-gradient(top, #efd100, #e2a233 19%, #f0c328 30%, #fff1a3 43%, #ffe13e 50%, #fff 51%, #fff);
            background-size: 1em 2.5em;
            color: #f8b700;
            display: inline-block;
            line-height: 1.5em;
            position: relative;
            z-index: auto;
            background-position: center 0;
            fill: #ffffff;
            &:hover {
            }
          }

          cursor: pointer;
        }
      }

      p {
        text-align: center;
        color: #ccc;
        font-weight: 300;
        margin-top: 30px;
      }
    }
  }
}
